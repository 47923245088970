import React from 'react';
import ReactDOM from 'react-dom';
import Normalize from 'react-normalize';
import { BrowserRouter } from 'react-router-dom';
import Router from './Components/Router/Router';

ReactDOM.render(
	<>
		<Normalize />
		<BrowserRouter>
			<Router />
		</BrowserRouter>
	</>,
	document.getElementById('root')
);
