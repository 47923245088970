import React from 'react';
import { Switch } from 'react-router-dom';
import Login from '../Login/Login';
import Dashboard from '../Dashboard/Dashboard';
import Layout from '../Layout/Layout';
import { NavigationRouter } from '../../NavRouter/NavigationRouter';

interface IRouterProps {}

const Router: React.FC<IRouterProps> = () => {
	//if checkauth 0 redirect to login
	//MISSING
	const routes = (
		<>
			<Switch>
				<NavigationRouter exact path='/' component={Dashboard} needAuth />
				<NavigationRouter path='/login' component={Login} exact />;
			</Switch>
		</>
	);
	return <Layout>{routes}</Layout>;
};
export default Router;
