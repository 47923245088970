import * as React from 'react';
import { INavigationRouter } from '../Interfaces/INavigationRouter.interface';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { ILocation } from '../Interfaces/IRouterProps.type';

/**
 * @param component the component that's to be rendered.
 * @param path the path to the route we want to generate.
 * @param exact a boolean that sets whether the route should be exact or not. This prop is
 * required for the routes to work properly in a <Switch></Switch> component block.
 * @param authenticated boolean for user is authenticated
 */

export const NavigationRouter = ({
	component: Component,
	...propList
}: INavigationRouter) => {
	const location: ILocation = useLocation();

	return (
		<Route
			exact={propList.exact}
			render={() => {
				window.scrollTo(0, 0);

				//if needAuth doesn't exist, the route will be accessible by anyone
				if (!propList.needAuth) {
					return <Component {...propList} />;
				}
				//if authenticated false, redirect to login page
				if (!propList.authenticated) {
					return (
						<Redirect
							to={{
								pathname: '/login',
								state: { from: location.pathname },
							}}
						/>
					);
				}
				//If authentication is passed lets return the private component
				if (propList.authenticated) {
					return <Component {...propList} />;
				}
			}}
		/>
	);
};
